.SelectableModal__buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.SelectableModal__buttons > button {
  width: 160px;
  height: 32px;
  padding: 0;
  font-size: var(--base-font-size);
  color: var(--white);
  cursor: pointer;
  background: none;
  background-color: var(--base-light);
  border: none;
  transition: background-color var(--duration) var(--easing);
}

.SelectableModal__buttons > button:hover {
  background-color: var(--base-color);
}
