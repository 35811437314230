.Switch {
  display: block;
}

.Switch > label {
  display: grid;
  grid-template-columns: 1fr 58px;
  gap: 12px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.Switch.-text > label {
  font-size: 12px;
  color: var(--white);
  text-transform: uppercase;
}

.Switch > label > span {
  display: inline-block;
  width: 58px;
  height: 34px;
  cursor: pointer;

  /* CSS Sprite */
  background-image: url("/assets/images/flip-switch.png");
  background-repeat: no-repeat;
  background-position: left top;

  /* for Retina Display */
  background-size: 58px 68px;
}

.Switch > input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: none;
}

.Switch > input[type="checkbox"]:checked + label > span {
  background-position: left bottom;
}

.Switch > label:focus:not(:focus-visible) {
  outline: none;
}
