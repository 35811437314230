.Modal {
  position: fixed;
  inset: 0;
  z-index: 9997;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Modal.-show {
  visibility: visible;
  opacity: 1;
  animation: modal-fade-in-animation var(--duration) var(--easing) 1 normal 0s forwards;
}

.Modal.-hidden {
  visibility: hidden;
  opacity: 0;
  animation: modal-fade-out-animation var(--duration) var(--easing) 1 normal 0s forwards;
}

@keyframes modal-fade-in-animation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes modal-fade-out-animation {
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.Modal__overlay {
  position: fixed;
  inset: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 60%);
}

.Modal__overlay[role="button"] {
  cursor: pointer;
}

.Modal__inner {
  position: fixed;
  z-index: 9999;
  min-width: 280px;
  background-color: var(--black);
}

.Modal__closer {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  padding: 0;
  line-height: 24px;
  color: var(--white);
  text-align: center;
  cursor: pointer;
  background: none;
  background-color: var(--base-light);
  border: none;
  border-radius: 12px;
  transition: background-color var(--duration) var(--easing);
}

.Modal__closer:hover {
  background-color: var(--base-color);
}

.Modal__title {
  padding: 8px;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: var(--white);
  background-color: var(--base-light);
}

.Modal__contents {
  margin: 24px;
  color: var(--base-light);
  text-align: center;
}
