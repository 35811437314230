.OscillatorSelector {
  display: grid;
  grid-template-columns: 63px 56px 56px 63px;
  width: 238px;
  margin: 0 auto;
}

.OscillatorSelector > label {
  display: block;
  height: 40px;
  cursor: pointer;

  /* CSS Sprite */
  background-image: url("/assets/images/radio-wave-type.png");
  background-repeat: no-repeat;

  /* for Retina Display */
  background-size: 238px 120px;
}

.OscillatorSelector > label > input[type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: none;
}

.OscillatorSelector__sine {
  width: 63px;
  background-position: 0 bottom;
}

.OscillatorSelector__sine:hover {
  background-position: 0 top;
}

.OscillatorSelector__sine:active,
.OscillatorSelector__sine.-active {
  background-position: 0 center;
}

.OscillatorSelector__square {
  width: 56px;
  background-position: -63px bottom;
}

.OscillatorSelector__square:hover {
  background-position: -63px top;
}

.OscillatorSelector__square:active,
.OscillatorSelector__square.-active {
  background-position: -63px center;
}

.OscillatorSelector__sawtooth {
  width: 56px;
  background-position: -119px bottom;
}

.OscillatorSelector__sawtooth:hover {
  background-position: -119px top;
}

.OscillatorSelector__sawtooth:active,
.OscillatorSelector__sawtooth.-active {
  background-position: -119px center;
}

.OscillatorSelector__triangle {
  width: 63px;
  background-position: -175px bottom;
}

.OscillatorSelector__triangle:hover {
  background-position: -175px top;
}

.OscillatorSelector__triangle:active,
.OscillatorSelector__triangle.-active {
  background-position: -175px center;
}
