.AudioFieldset {
  padding: 12px 0;
}

.AudioFieldset__selectAudio {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
  align-items: center;
}
