.SpectrumAnalyser {
  padding: 0;
  margin: 0;
}

.SpectrumAnalyser > dt {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: var(--white);
  text-transform: uppercase;
}

.SpectrumAnalyser > dd {
  padding: 0;
  margin: 12px 0 0;
  border: 2px solid rgb(255 255 255 / 5%);
  box-shadow:
    0 0 0 2px var(--black),
    0 -1px 1px 5px rgb(0 0 0 / 50%);
}
