.RecorderFieldset {
  padding: 12px 0;
}

.RecorderFieldset__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 238px;
  margin: 0 auto;
}

.RecorderFieldset__controller {
  width: 63px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  background: none;

  /* CSS Sprite */
  background-image: url("/assets/images/buttons-record.png");
  background-repeat: no-repeat;
  background-position: 0 bottom;

  /* for Retina Display */
  background-size: 238px 120px;
  border: none;
}

.RecorderFieldset__controller:hover {
  background-position: 0 top;
}

.RecorderFieldset__controller:active,
.RecorderFieldset__controller.-active {
  background-position: 0 center;
}

.RecorderFieldset__creator {
  width: 56px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  background: none;

  /* CSS Sprite */
  background-image: url("/assets/images/buttons-record.png");
  background-repeat: no-repeat;
  background-position: -63px bottom;

  /* for Retina Display */
  background-size: 238px 120px;
  border: none;
}

.RecorderFieldset__creator:disabled {
  cursor: not-allowed;
  opacity: var(--opacity);
}

.RecorderFieldset__creator:not(:disabled):hover {
  background-position: -63px top;
}

.RecorderFieldset__creator:not(:disabled):active,
.RecorderFieldset__creator.-active {
  background-position: -63px center;
}

.RecorderFieldset__download {
  display: block;
  width: 56px;
  height: 40px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;

  /* CSS Sprite */
  background-image: url("/assets/images/buttons-record.png");
  background-repeat: no-repeat;
  background-position: -119px bottom;

  /* for Retina Display */
  background-size: 238px 120px;
}

button[type="button"].RecorderFieldset__download {
  padding: 0;
  cursor: pointer;
  background: none;

  /* CSS Sprite */
  background-image: url("/assets/images/buttons-record.png");
  background-repeat: no-repeat;
  background-position: -119px bottom;

  /* for Retina Display */
  background-size: 238px 120px;
  border: none;
}

button[type="button"].RecorderFieldset__download:disabled {
  cursor: not-allowed;
  opacity: var(--opacity);
}

a.RecorderFieldset__download:hover {
  background-position: -119px top;
  opacity: 1;
}

a.RecorderFieldset__downloadactive {
  background-position: -119px center;
}

.RecorderFieldset__clear {
  width: 63px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  background: none;

  /* CSS Sprite */
  background-image: url("/assets/images/buttons-record.png");
  background-repeat: no-repeat;
  background-position: -175px bottom;

  /* for Retina Display */
  background-size: 238px 120px;
  border: none;
}

.RecorderFieldset__clear:not(:disabled):hover {
  background-position: -175px top;
}

.RecorderFieldset__clear:not(:disabled):active {
  background-position: -175px center;
}
