.Footer {
  display: flex;
  gap: 12px;
  justify-content: center;
  height: 100%;

  /* border-top: 1px solid var(--black); */

  padding: 32px 12px;
  margin-top: 50px;
  font-size: 12px;
  color: var(--white);

  /* text-shadow:-1px -1px 0 var(--black), 1px 1px 0 var(--black); */

  background-color: rgb(255 255 255 / 5%);
  box-shadow: 0 0 3px rgb(255 255 255 / 50%);

  /* Wait for completing layout */

  /* opacity: 0; */
}

.Footer > p {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}
