.Spinner {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 4px 0 4px 8px;
  font-size: 13px;
  color: var(--white);
  cursor: pointer;
  user-select: none;
  background-color: var(--black);
  background-image: linear-gradient(to bottom, var(--gray-deep), var(--black));
  border: 2px solid rgb(255 255 255 / 5%);
  outline: none;
  box-shadow:
    5px 5px 3px rgb(0 0 0 / 80%) inset,
    3px -3px 3px rgb(0 0 0 / 50%) inset,
    0 0 0 2px var(--black) inset,
    0 -1px 1px 2px rgb(0 0 0 / 50%); /* outer (for emboss) */

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: var(--transparent);
}

.Spinner:focus,
.Spinner:active {
  background-color: var(--base-color);
  background-image: linear-gradient(to bottom, var(--base-color), var(--base-light));
  box-shadow:
    5px 5px 3px rgb(0 0 0 / 40%) inset,
    3px -3px 3px rgb(0 0 0 / 20%) inset,
    0 -1px 5px 1px rgb(17 17 17 / 75%); /* outer (for emboss) */
}
