.ParameterController {
  width: 100%;
  max-width: 300px;
  padding: 0;
  margin: 0;
}

.ParameterController > dt {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  padding: 0 4px;
  margin: 0;
}

.ParameterController > dt > label {
  font-size: 12px;
  color: var(--white);
  text-transform: uppercase;
}

.ParameterController > dd {
  padding: 0;
  margin: 4px 0 0;
}
