.Legend {
  display: block;
  width: 100%;
  margin: 0 auto 8px;
  font-family: Neuton, serif;
  font-size: 22px;
  font-weight: lighter;
  color: var(--base-light);
  text-transform: uppercase;
}
