.Slider {
  position: relative;
  z-index: 1;
  margin: 0 auto;
}

.Slider > input[type="range"] {
  box-sizing: border-box;
  width: 100%;
  height: 6px;
  appearance: none;
  user-select: none;
  background: transparent;
  background-color: #333;
  border-top: 1px solid rgb(255 255 255 / 30%);
  border-right: none;
  border-bottom: 1px solid rgb(255 255 255 / 30%);
  border-left: none;
  border-radius: 4px;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: var(--transparent);
}

.Slider > input[type="range"]:focus:not(:focus-visible) {
  outline: none;
}

.Slider > input[type="range"]::-webkit-slider-thumb {
  box-sizing: border-box;
  width: 20px; /* TODO: resize image */
  height: 23px;
  margin-top: -2px;
  appearance: none;
  cursor: pointer;
  background-color: var(--transparent);

  /* CSS Sprite */
  background-image: url("/assets/images/slider-handles.png");
  background-repeat: no-repeat;
  background-position: 0 -23px;
  background-size: 23px 46px;
  border: none;
}

.Slider > input[type="range"]::-moz-range-thumb {
  box-sizing: border-box;
  width: 20px; /* TODO: resize image */
  height: 23px;
  margin-top: -9px;
  appearance: none;
  cursor: pointer;
  background-color: var(--transparent);

  /* CSS Sprite */
  background-image: url("/assets/images/slider-handles.png");
  background-repeat: no-repeat;
  background-position: 0 -23px;
  background-size: 23px 46px;
  border: none;
}

.Slider > input[type="range"]:active::-webkit-slider-thumb {
  cursor: ew-resize;
  background-position: 0 0;
}

.Slider > input[type="range"]:active::-moz-range-thumb {
  cursor: ew-resize;
  background-position: 0 0;
}
