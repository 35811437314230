.FileUploader {
  display: block;
}

.FileUploader > button,
.FileUploader.-drop > button {
  box-sizing: border-box;
  width: 180px;
  height: 28px;
  padding: 4px;
  overflow: hidden;
  font-size: 9px;
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--black);
  background-image: linear-gradient(to bottom, var(--gray), var(--black));
  border: 2px solid rgb(255 255 255 / 5%);
  box-shadow:
    0 0 0 1px var(--black) inset,
    0 0 1px 3px rgb(0 0 0 / 30%); /* outer (for emboss) */
}

.FileUploader > button[type="button"]:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.FileUploader > button:not(:disabled):focus,
.FileUploader > button:not(:disabled):active,
.FileUploader.-drag > button:not(:disabled) {
  background-color: var(--base-light);
  background-image: linear-gradient(to bottom, var(--base-color), var(--base-light));
  border: 2px solid rgb(255 255 255 / 5%);
}

.FileUploader > input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: none;
}
