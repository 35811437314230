body {
  min-width: 320px;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: var(--base-font-size);
  line-height: 1.5;
  color: var(--gray);
  background-color: var(--black);
}

noscript {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: 100dvh;
  font-family: Alike, serif;
  font-size: var(--base-font-size);
  color: var(--base-light);
}

h1 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.Application {
  min-width: 1280px; /* for Piano width */
  max-width: 1400px;
  margin: 0 auto;
}

[hidden] {
  display: none !important;
}
