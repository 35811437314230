.Button {
  display: block;
  padding: 0;
  cursor: pointer;
  background: none;
  background-repeat: no-repeat;

  /* CSS Sprite */
  background-position: left bottom;
  border: none;
}

.Button:hover {
  background-position: left top;
}

.Button:active,
.Button.-active {
  background-position: left center;
}

.Button.-active:active {
  background-position: left bottom;
}
