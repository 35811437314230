.TimeOverviewer {
  padding: 0;
  margin: 0;
}

.TimeOverviewer > dt {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: var(--white);
  text-transform: uppercase;
}

.TimeOverviewer > dd {
  width: 420px;
  padding: 0;
  margin: 8px 0 0;
  overflow-x: auto;
  border: 2px solid rgb(255 255 255 / 5%);
  box-shadow:
    0 0 0 2px var(--black),
    0 -1px 1px 5px rgb(0 0 0 / 50%);
}

.TimeOverviewer > dt > label {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.TimeOverviewer__channelSelector {
  display: block;
  width: 50px;
  height: 22px;
  padding: 0;
  cursor: pointer;
  background: none;

  /* CSS Sprite */
  background-image: url("/assets/images/flip-switch-channel.png");
  background-repeat: no-repeat;

  /* for Retina Display */
  background-size: 50px 44px;
  border: none;
}

.TimeOverviewer__channelSelector.-left {
  background-position: left top;
}

.TimeOverviewer__channelSelector.-right {
  background-position: left bottom;
}

.TimeOverviewer__dragTime {
  margin-left: 12px;
}
