.Header {
  position: fixed;
  inset: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: rgb(0 0 0 / 80%);
}

.Header.-progress {
  color: var(--base-light);
  background-color: rgb(0 0 0 / 80%);
}

.Header.-fadeOut {
  animation: fade-out-header-animation 1.5s var(--easing) 1 normal 0s forwards;
}

.Header__forkMeOnGitHub > a {
  position: absolute;
  top: 60px;
  right: -60px;
  width: 280px;
  padding: 5px 40px;
  font-family: arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 2rem;
  color: #d1d1d1;
  text-align: center;
  text-decoration: none;
  background-color: #222;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 80%);
  transition: 0.6s;
  transform: rotate(45deg);
}

.Header__intro > span > a {
  margin: 0 4px;
}

.Header__forkMeOnGitHub > a:hover {
  color: #d1d1d1;
  text-decoration: none;
  background-color: #6d6d6d;
}

.Header__forkMeOnGitHub > a::before,
.Header__forkMeOnGitHub > a::after {
  position: absolute;
  top: 1px;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  content: "";
  background-color: #d1d1d1;
}

.Header__forkMeOnGitHub > a::after {
  top: auto;
  bottom: 1px;
}

@keyframes fade-out-header-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.Header > div {
  margin-top: -120px;
}

.Header__logo {
  /* for animation */
  position: relative;
  z-index: 1;

  /* size of logo */
  width: 200px;
  height: 100px;
  margin: 0 auto;
  font-size: 36px;
  text-align: center;

  /* Wait for completing layout */

  /* opacity:0; */
}

.Header.-fadeIn .Header__logo {
  animation: fade-in-header-animation 1.5s ease-in 1 normal 0s forwards;
}

@keyframes fade-in-header-animation {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}

.Header__logo > img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.Header__logo > img:not(:first-child) {
  opacity: 0;
}

.Header.-fadeOut .Header__logo > img:nth-of-type(1) {
  animation: logo-move-left 3s linear 1 normal 0s forwards;
}

.Header.-fadeOut .Header__logo > img:nth-of-type(2) {
  animation: logo-move-left 3s linear 1 normal 0.125s forwards;
}

.Header.-fadeOut .Header__logo > img:nth-of-type(3) {
  animation: logo-move-left 3s linear 1 normal 0.25s forwards;
}

.Header.-fadeOut .Header__logo > img:nth-of-type(4) {
  animation: logo-move-right 3s linear 1 normal 0s forwards;
}

.Header.-fadeOut .Header__logo > img:nth-of-type(5) {
  animation: logo-move-right 3s linear 1 normal 0.125s forwards;
}

.Header.-fadeOut .Header__logo > img:nth-of-type(6) {
  animation: logo-move-right 3s linear 1 normal 0.25s forwards;
}

@keyframes logo-move-left {
  0% {
    opacity: 0.5;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-12000px);
  }
}

@keyframes logo-move-right {
  0% {
    opacity: 0.5;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(12000px);
  }
}

.Header__intro {
  width: 680px;
  margin: 12px auto 24px;
  font-family: Alike, serif;
  font-size: 18px;
  color: var(--base-light);
  text-align: left !important;
  text-shadow: -1px -1px 1px var(--black);
}

.Header__intro > span {
  /* for animation */
  display: block;
}

.Header.-fadeOut .Header__intro > .Header__moveLeft {
  animation: line-left-animation 1s ease-out 1 normal 0s;
}

.Header.-fadeOut .Header__intro > .Header__moveRight {
  animation: line-right-animation 1s ease-out 1 normal 0s;
}

.Header__intro > span > strong {
  font-weight: normal;
}

@keyframes line-right-animation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(2500px);
  }
}

@keyframes line-left-animation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-2500px);
  }
}

.Header__startButton {
  /* Wait for loading one-shot audio */

  /* opacity:0; */

  text-align: center;
}

.Header__startButton > button {
  display: block;
  width: 136px;
  height: 50px;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  text-indent: 136px;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;

  /* CSS Sprite */
  background-image: url("/assets/images/button-start-application.png");
  background-repeat: no-repeat;
  background-position: left top;

  /* for Retina Display */
  background-size: 136px 150px;
  border: none;
}

.Header__startButton > button:hover {
  background-position: left center;
}

.Header__startButton > button:active {
  background-position: left bottom;
}

.Header__startButton > button:focus {
  background-position: left bottom;
}
