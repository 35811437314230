.Piano {
  position: relative;
  z-index: 1;
  width: 1256px;
  height: 240px;
  margin: 32px auto;
  user-select: none;
  background-color: var(--gray);
  background-image: url("/assets/images/piano-frame-crystal.png");
  background-repeat: no-repeat;
  background-position: left top;

  /* for Retina Display */
  background-size: 1256px 240px;

  /* emboss */

  /* box-shadow: 0 0 0 3px var(--black) inset, 0 -1px 5px 9px rgba(17, 17, 17, 0.4); */

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: var(--transparent);
}

/* Reference | Size of piano keyboard (white) */

/* width : height -> 23 : 150 */
.Piano__whites {
  position: absolute;
  top: 73px;
  left: 30px;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(52, 23px);
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: var(--white);
}

.Piano__whites > .Piano__keyboard {
  box-sizing: border-box;
  width: 23px;
  height: 150px;
  background-color: var(--white);

  /* CSS Sprite */
  background-image: url("/assets/images/piano-keyboard-white.png");
  background-repeat: no-repeat;
  background-position: left bottom;

  /* for Retina Display */
  background-size: 23px 300px;
}

.Piano__whites > .Piano__keyboard.-active {
  background-position: left top;
}

/* Reference | Size of piano keyboard (black) */

/* width : height -> 11 : 95 */
.Piano__blacks {
  position: absolute;
  top: 73px;
  left: 47px;
  z-index: 3;
  display: grid;
  grid-template-columns: repeat(50, 11px);
  grid-column-gap: 12px;
  height: 95px; /* Enable to click keyboard */
  padding: 0;
  margin: 0;
  list-style: none;
}

.Piano__blacks > .Piano__keyboard {
  box-sizing: border-box;
  width: 11px;
  height: 95px;
  cursor: pointer;
  background-color: var(--black);

  /* CSS Sprite */
  background-image: url("/assets/images/piano-keyboard-black.png");
  background-repeat: no-repeat;
  background-position: left bottom;

  /* for Retina Display */
  background-size: 11px 190px;
}

.Piano__blacks > .Piano__keyboard.-active {
  background-position: left top;
}

.Piano__keyboard > button {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  cursor: pointer;
  background: none;
  border: none;
}

.Piano__keyboard > button:focus:not(:focus-visible) {
  outline: none;
}

.Piano__blacks > .Piano__keyboard.-skip {
  height: 1px;
  visibility: hidden;
  background: none !important;
  background-color: var(--transparent) !important;
}
