:root {
  --base-font-size: 16px;
  --base-color: #800080;
  --base-light: #d0d;
  --white: #fff;
  --gray-lightest: #fafafa;
  --gray-lighter: #ccc;
  --gray-light: #999;
  --gray: #666;
  --gray-deep: #333;
  --black-light: #212121;
  --black: #000;
  --opacity: 0.6;
  --transparent: rgb(0 0 0 / 0%);
  --duration: 0.6s;
  --easing: ease;
}
